@import '../../style.scss';
@font-face {
    font-family: 'Genos';
    src: url('../../assets/fonts/Genos-VariableFont_wght.ttf');
}


header{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    background-color: $violet;
    .logOut{
        display: flex;
        align-items: center;
        background-color: transparent;
        border-width: 0px;
        justify-content: space-between;
        transition: all 0.5s;
        &:hover{
            transform: scale(1.1);
            img{
                animation: logOut 0.5s  forwards;
            }
        }

        p{
            font-family: 'Kodchasan';
        }
        img{
            margin-left: 10px;
            width: 10px;
        }
    }
    .backButton{
        display: none;
    }
    h1{
        color:$bleu;
        font-family: 'Genos';
        z-index: 2;
    }
    .logo{
        right: 0;
        background-color: transparent;
        border: none;
        width: 20%;
        height: 100px;
        max-width: 200px;
        background-image: url('../../assets/logo/LOGO1.png');
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;


    }
}
@media screen and (max-width:900px) {
    header{
        display: flex;
        flex-direction: column-reverse;
        justify-content:space-around;
        align-items: center;
        background-color: $violet;
        position: relative;
        .logOut{
            display: none;
            align-items: center;
            background-color: transparent;
            border-width: 0px;
            justify-content: start;
            transition: all 0.5s;
            position: absolute;
            left: 0px;
            top: 0px;
            &:hover{
                transform: scale(1.1);
                img{
                    animation: logOut 0.5s  forwards;
                }
            }
    
            p{
                font-family: 'Kodchasan';
            }
            img{
                margin-left: 10px;
                width: 10px;
                
            }
        }
        .backButton{
            display: block;
            position: absolute;
            top: 0px;
            left: 5px;
            z-index: 5;
            .back{
                height: 50px;
                width: 50px;

            }
        }
        h1{
            margin-top: 60px;
            text-align: center;
            color:$bleu;
            font-family: 'Genos';
            z-index: 2;
            padding-left: 20px;
        }
        .logo{
            display: none;
            right: 0;
            background-color: transparent;
            border: none;
            width: 20%;
            min-width: 200px;
            height: 100px;
            max-width: 200px;
            background-image: url('../../assets/logo/LOGO1.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            align-self:flex-end;
    
    
        }
    }

    
}



@keyframes logOut {
    0%{

    }
    100%{
        transform: rotateZ(360deg);
    }
    
}