@font-face {
  font-family: 'Kodshasan';
  src: url("../../assets/fonts/Kodchasan-Light.ttf");
}

article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 200px;
  min-width: 200px;
  font-family: 'Kodshasan';
  border-radius: 20px;
  margin: 8px;
  background-color: #ff9f2c;
  padding-bottom: 0px;
}

article h3 {
  text-align: center;
  font-weight: 300;
  font-family: 'Kodshasan';
  font-size: 20px;
  margin: 0%;
}

article .details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: beige;
}

article .details .categorie {
  text-align: center;
  font-size: larger;
}

article .details .cadreHoraire {
  border-width: 1px;
  border-style: double;
  width: 80%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

article .details .cadreHoraire p {
  text-align: center;
  margin: 0%;
  padding: 0%;
}

article .details p {
  margin-bottom: 0px;
}

article .detailsPlus .modification {
  text-align: center;
  background-color: black;
  color: white;
  border-radius: 10px;
  position: relative;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  border-style: none;
  width: 100%;
  font-family: 'Kodchasan';
  font-size: 15px;
}

article .detailsPlus .modification:hover {
  background-color: #386781;
}

article .detailsPlus .modification img {
  position: absolute;
  width: 20px;
  top: 30%;
  right: 10%;
}

.theatre {
  background-color: #ff9f2c;
}

.theatre .details {
  background-color: #faff00;
}

.concert {
  background-color: #ff9f2c;
}

.concert .details {
  background-color: rgba(127, 252, 78, 0.362);
}

.cinema {
  background-color: #ff9f2c;
}

.cinema .details {
  background-color: #648bff;
}

@media screen and (max-width: 900px) {
  article {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 90%;
    min-width: 200px;
    font-family: 'Kodshasan';
    border-radius: 20px;
    margin: 8px;
    background-color: #ff9f2c;
    padding-bottom: 0px;
  }
  article h3 {
    text-align: center;
    font-weight: 300;
    font-family: 'Kodshasan';
    font-size: 20px;
    margin: 0%;
  }
  article .details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: beige;
  }
  article .details .categorie {
    text-align: center;
    font-size: larger;
  }
  article .details .cadreHoraire {
    border-width: 1px;
    border-style: double;
    width: 80%;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  article .details .cadreHoraire p {
    text-align: center;
    margin: 0%;
    padding: 0%;
  }
  article .details p {
    margin-bottom: 0px;
  }
  article .detailsPlus .modification {
    text-align: center;
    background-color: black;
    color: white;
    border-radius: 10px;
    position: relative;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    border-style: none;
    width: 100%;
    font-family: 'Kodchasan';
    font-size: 15px;
  }
  article .detailsPlus .modification:hover {
    background-color: #386781;
  }
  article .detailsPlus .modification img {
    position: absolute;
    width: 20px;
    top: 30%;
    right: 10%;
  }
  .theatre {
    background-color: #ff9f2c;
  }
  .theatre .details {
    background-color: #faff00;
  }
  .concert {
    background-color: #ff9f2c;
  }
  .concert .details {
    background-color: rgba(127, 252, 78, 0.362);
  }
  .cinema {
    background-color: #ff9f2c;
  }
  .cinema .details {
    background-color: #648bff;
  }
}
