@import '../../style.scss';
@font-face {
    font-family: 'Kodchasan';
    src: url('../../assets/fonts/Kodchasan-Light.ttf');
}


.NavigationBar{
    position: fixed;
    height: 100%;
    background-color: $bleu;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 200px;
    z-index: 2;
    button{
        background-color: transparent;
        border: none;
        p{
            font-family: 'Kodchasan';
            color: $violet;
        }
        img{
            width: 20%;
            min-width: 25px;
        }

    }
    
}
@media screen and (max-width:900px){
    .NavigationBar{
        
        position: fixed;
        height: 100%;
        background-color: $bleu;
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: 200px;
        z-index: 2;
        display: none;
        button{
            background-color: transparent;
            border: none;
            p{
                font-family: 'Kodchasan';
                color: $violet;
            }
            img{
                width: 20%;
                min-width: 25px;
            }
    
        }
        
    }

}