@import '../../style.scss';
.waiter {
    width: 20px;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.535);
    z-index: 3;
    .cadre {
        background-color: $violet;
        opacity: 1;
        padding: 45px;
        border-radius: 20px;
        width: 200px;
        margin-left: 30%;
        margin-right: auto;
        margin-top: 10%;
        img {
            width: 200px;
            animation: roll 5s infinite;
        }
    }
}

@keyframes roll {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}