


.ForgotPassword{
    background-color: #fff;
    padding: 10px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    input{
        text-align: center;
        width: 50%;
        height: 40px  !important;
        font-size: 20px;
    }
    button{
        margin-top: 10px;
        width: 100%;
        background-color: #000;
        color: #fff;
        border: none;
        padding: 10px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
    }

}