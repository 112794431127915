@font-face {
  font-family: 'Kodchasan';
  src: url("../../../assets/fonts/Kodchasan-Light.ttf");
}

.selectorCate .logo {
  margin-left: 20px;
  color: #D7BCE7;
}

.selectorCate select {
  color: #386781;
  text-align: center;
  font-family: 'Kodchasan';
}

.selectorCate .infoCat {
  color: #D7BCE7;
  margin-left: 10px;
}

.selectorCate .infoCat p {
  font-size: 12px;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.selectorCate .infoCat label {
  font-size: 12px;
}

@media screen and (max-width: 900px) {
  .selectorCate {
    width: 100%;
  }
  .selectorCate p {
    text-align: center;
  }
  .selectorCate .logo {
    margin-left: 20px;
    color: #D7BCE7;
  }
  .selectorCate select {
    color: #386781;
    text-align: center;
    font-family: 'Kodchasan';
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .selectorCate .infoCat {
    color: #D7BCE7;
    margin-left: 10px;
  }
  .selectorCate .infoCat p {
    font-size: 15px;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .selectorCate .infoCat label {
    font-size: 12px;
  }
}
