@import '../../../style.scss';
.indice{
  .etoiles{
    margin-top: 10px;
    overflow: hidden;
    .inactive{
        color:$violet;
    }
    .active{
        animation: animateEtoile 2s forwards;
    }
    button{
        width: 1.5em;
        background-color: transparent;
        border: 0px;
        margin: 0px;
        padding: 0px;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    
}  
.avis{
    position: absolute;
    font-size: 9px;
    margin: 0px;
    color: greenyellow;
    animation: avis 1s;
}
}
@media screen and (max-width:900px) {
    .indice{
        width: 100%;
        
        .etoiles{
          margin-top: 50px;
          overflow: hidden;
          width: 100%;
          display: flex;
          justify-content: space-around;
          .inactive{
              color:$violet;
          }
          .active{
              animation: animateEtoile 2s forwards;
          }
          button{
              width: 10%;
              background-color: transparent;
              border: 0px;
              margin: 0px;
              padding: 0px;
              svg{
                  width: 100%;
                  height: 100%;
              }
          }
          
      }  
      .avis{
          position: absolute;
          font-size: 15px;
          margin: 0px;
          color: greenyellow;
          animation: avis 1s;
      }
      }

    
}

@keyframes animateEtoile {
    0%{
        transform: rotateZ(0deg);

    }
    30%{
        transform: rotateZ(360deg);
        color:yellow
    }
    60%{
        transform: scale(1.1);
    }
    100%{
        color:yellow;
        transform: rotateZ(360deg);
        transform: scale(1);

    }
    
}
@keyframes avis {
    0%{
        opacity: 0;
        transform: translatex(-60px);
    }
    100%{
        transform:translateX(0) ;
        opacity: 1;
    }
    
}