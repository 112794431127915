@font-face {
    font-family: 'Kodshasan';
    src: url('../../assets/fonts/Kodchasan-Light.ttf');
}
@import '../../style.scss';

article {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 200px;
    min-width: 200px;
    font-family: 'Kodshasan';
    border-radius: 20px;
    margin: 8px;
    background-color: rgb(255, 159, 44);
    padding-bottom: 0px;
    h3 {
        text-align: center;
        font-weight: 300;
        font-family: 'Kodshasan';
        font-size: 20px;
        margin: 0%;
        
    }
    .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: beige;
        .categorie {
            text-align: center;
            font-size: larger;
        }
        .cadreHoraire {
            border-width: 1px;
            border-style: double;
            width: 80%;
            align-self: center;
            p {
                text-align: center;
                margin: 0%;
                padding: 0%;
            }
        }
        .titre {

        }
        p{
            margin-bottom: 0px;
        }
    }
    .detailsPlus{
        .modification{
            text-align: center;
            background-color: black;
            color: white;
            border-radius: 10px;
            position: relative;
            transition: all 500ms;
            border-style: none;
            width: 100%;
            font-family: 'Kodchasan';
            font-size: 15px;
            &:hover{
                background-color: $bleu;
            }
            img{
                position: absolute;
                width: 20px;
                top:30%;
                right: 10%;
            }
        }

    }
}

.theatre {
    background-color: rgb(255, 159, 44);
    .details {
        background-color: rgb(250, 255, 0);
    }
}

.concert {
    background-color: rgb(255, 159, 44);
    .details {
        background-color: rgba(127, 252, 78, 0.362);
    }
}

.cinema {
    background-color: rgb(255, 159, 44);
    .details {
        background-color: rgb(100, 139, 255);
        ;
    }
}
@media screen  and (max-width:900px){
    article {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 90%;
        min-width: 200px;
        font-family: 'Kodshasan';
        border-radius: 20px;
        margin: 8px;
        background-color: rgb(255, 159, 44);
        padding-bottom: 0px;
        h3 {
            text-align: center;
            font-weight: 300;
            font-family: 'Kodshasan';
            font-size: 20px;
            margin: 0%;
            
        }
        .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: beige;
            .categorie {
                text-align: center;
                font-size: larger;
            }
            .cadreHoraire {
                border-width: 1px;
                border-style: double;
                width: 80%;
                align-self: center;
                p {
                    text-align: center;
                    margin: 0%;
                    padding: 0%;
                }
            }
            .titre {
    
            }
            p{
                margin-bottom: 0px;
            }
        }
        .detailsPlus{
            .modification{
                text-align: center;
                background-color: black;
                color: white;
                border-radius: 10px;
                position: relative;
                transition: all 500ms;
                border-style: none;
                width: 100%;
                font-family: 'Kodchasan';
                font-size: 15px;
                &:hover{
                    background-color: $bleu;
                }
                img{
                    position: absolute;
                    width: 20px;
                    top:30%;
                    right: 10%;
                }
            }
    
        }
    }
    
    .theatre {
        background-color: rgb(255, 159, 44);
        .details {
            background-color: rgb(250, 255, 0);
        }
    }
    
    .concert {
        background-color: rgb(255, 159, 44);
        .details {
            background-color: rgba(127, 252, 78, 0.362);
        }
    }
    
    .cinema {
        background-color: rgb(255, 159, 44);
        .details {
            background-color: rgb(100, 139, 255);
            ;
        }
    }
    
}