.CGU {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h1 {
        font-size: 2.5rem;
        font-weight: 600;
        color: #000;
        margin-bottom: 1rem;
        text-align: center;
        border: 1px solid #000;
        padding: 1rem;
        width: 80%;
        margin: auto auto;
        margin-top: 50px;
    }
    h2 {
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        margin-bottom: 1rem;
        border-bottom: 2px solid #000;
    }
    p {
        font-size: 1.5rem;
        font-weight: 400;
        color: #000;
        margin-bottom: 1rem;
        width: 80%;
    }
}