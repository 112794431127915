@font-face {
    font-family: 'Kodchasan';
    src: url('../../assets/fonts/Kodchasan-Light.ttf');
}

@import '../../style.scss';
.LogIn {
    font-family: 'Kodchasan';
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $bleu;
    padding-top: 5%;
    position: fixed;
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 80%;
        min-height: 350px;
        background-color: $violet;
        border-radius: 20px;
        overflow: hidden;
        .phraseError {
            font-size: 12px;
            color: red;
        }
        .logo {
            margin: 0px;
            padding: 0px;
            background-color: transparent;
            border-width: 0px;
            max-width: 150px;
            width: 200px;
            img {
                opacity: 0;
                transform: translate(-200px);
                margin-top: 20px;
                width: 100%;
                animation: logo 1s 500ms forwards;
            }
        }
         ::placeholder {
            color: $violet;
            text-align: center;
            font-family: 'Kodchasan';
        }
         ::content {
            color: white
        }
        .email {
            margin-top: 20px;
            background-color: $bleu;
            width: 80%;
            border: none;
            height: 40px;
            max-width: 400px;
            color: $violet;
            font-family: 'Kodchasan';
            font-size: 20px;
        }
        .motDePasse {
            margin-top: 20px;
            background-color: $bleu;
            width: 80%;
            border: none;
            height: 40px;
            max-width: 400px;
            color: $violet;
            font-family: 'Kodchasan';
            font-size: 20px;
        }
        .motDePasseOublie {
            text-align: start;
            color: $bleu;
            font-size: 12px;
        }
        .submit {
            margin-bottom: 20px;
            padding: 10px;
            border-style: double;
            border-width: 2px;
            border-color: $bleu;
            background-color: transparent;
            font-size: 20px;
            font-family: 'Kodchasan';
            color: $bleu;
            transition: all 500ms;
            &:hover{
                background-color: $bleu;
                color: $violet;
            }
        }
    }
    .waiting {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $violet;
        border-radius: 20px;
        height: 200px;
        img {
            width: 50%;
            max-width: 400px;
        }
        .waitingRoute {
            position: relative;
            img {
                width: 10%;
                animation: vieux 20s infinite linear;
            }
        }
        p {
            color: $bleu;
            animation: connection 1s infinite;
        }
    }
}

@media (max-width:900px) {
    .LogIn{
        font-family: 'Kodchasan';
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 0%;
    position: fixed;
    main{
        width: 100%;
        height: 100%;
        border-radius: 0px;
       
    }
    .waiting {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $violet;
        border-radius: 0px;
        width: 100%;
        img {
            width: 50%;
            max-width: 400px;
        }
        p {
            color: $bleu;
            animation: connection 1s infinite;
        }
    }

    }
    
}

@keyframes logo {
    0% {
        opacity: 0;
        
    }
    100% {
        opacity: 1;
        transform: translate(0px);
    }
}

@keyframes connection {
    0% {
        transform: scale(1);
    }
    30% {
        opacity: 0.7;
        transform: scale(1);
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

