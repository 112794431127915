.indice .etoiles {
  margin-top: 10px;
  overflow: hidden;
}

.indice .etoiles .inactive {
  color: #D7BCE7;
}

.indice .etoiles .active {
  -webkit-animation: animateEtoile 2s forwards;
          animation: animateEtoile 2s forwards;
}

.indice .etoiles button {
  width: 1.5em;
  background-color: transparent;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.indice .etoiles button svg {
  width: 100%;
  height: 100%;
}

.indice .avis {
  position: absolute;
  font-size: 9px;
  margin: 0px;
  color: greenyellow;
  -webkit-animation: avis 1s;
          animation: avis 1s;
}

@media screen and (max-width: 900px) {
  .indice {
    width: 100%;
  }
  .indice .etoiles {
    margin-top: 50px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .indice .etoiles .inactive {
    color: #D7BCE7;
  }
  .indice .etoiles .active {
    -webkit-animation: animateEtoile 2s forwards;
            animation: animateEtoile 2s forwards;
  }
  .indice .etoiles button {
    width: 10%;
    background-color: transparent;
    border: 0px;
    margin: 0px;
    padding: 0px;
  }
  .indice .etoiles button svg {
    width: 100%;
    height: 100%;
  }
  .indice .avis {
    position: absolute;
    font-size: 15px;
    margin: 0px;
    color: greenyellow;
    -webkit-animation: avis 1s;
            animation: avis 1s;
  }
}

@-webkit-keyframes animateEtoile {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  30% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
    color: yellow;
  }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    color: yellow;
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes animateEtoile {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  30% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
    color: yellow;
  }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    color: yellow;
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes avis {
  0% {
    opacity: 0;
    -webkit-transform: translatex(-60px);
            transform: translatex(-60px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes avis {
  0% {
    opacity: 0;
    -webkit-transform: translatex(-60px);
            transform: translatex(-60px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
