@font-face {
  font-family: 'Kodchasan';
  src: url("../../assets/fonts/Kodchasan-Light.ttf");
}

.Acceuil {
  margin-top: 0px;
  background-image: url("https://assets.papyhappy.com/FR/institution/covers/dc85e9ee931515f36750d4ad0770eec50a76e4dc.jpg");
}

.Acceuil main {
  background-color: rgba(215, 188, 231, 0.9);
  margin-top: 0%;
  padding: 20px;
  color: #D7BCE7;
  width: 80%;
  text-align: center;
  font-family: 'Kodchasan';
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-left: 15%;
}

.Acceuil .NavigationMobil {
  display: none;
}

.Acceuil .descriptionOrdinateur {
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
  color: black;
  background-size: cover;
  background-position: center;
}

.Acceuil .descriptionOrdinateur p {
  line-height: 2.5em;
}

@media screen and (max-width: 900px) {
  .Acceuil {
    margin-top: 0px;
  }
  .Acceuil main {
    margin-top: 0%;
    padding: 0px;
    color: #D7BCE7;
    width: 100%;
    text-align: center;
    font-family: 'Kodchasan';
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-left: 0%;
  }
  .Acceuil main header {
    display: none;
  }
  .Acceuil main .NavigationMobil {
    display: block;
  }
  .Acceuil main .NavigationMobil .logoMobil {
    width: 100%;
  }
  .Acceuil main .NavigationMobil .logoMobil img {
    opacity: 0;
    width: 50%;
    -webkit-animation: logoMobil 1s forwards;
            animation: logoMobil 1s forwards;
    border: 2px;
    border-style: solid;
    border-color: #386781;
    border-radius: 3px;
  }
  .Acceuil main .NavigationMobil .proposer {
    background-color: #386781;
    width: 100%;
    font-size: 30px;
  }
  .Acceuil main .NavigationMobil img {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 20%;
  }
  .Acceuil main .descriptionOrdinateur {
    display: none;
  }
}

@-webkit-keyframes logoMobil {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes logoMobil {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
