@font-face {
  font-family: 'Kodchasan';
  src: url("../../assets/fonts/Kodchasan-Light.ttf");
}

.Proposer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #D7BCE7;
}

.Proposer main {
  padding-left: 15%;
  margin-bottom: 100px;
}

.Proposer main .FormCadre {
  padding-top: 20px;
  background-color: #386781;
  border-radius: 5px;
  margin: 10px;
  margin-top: 40px;
  padding-left: 5%;
  padding-right: 5%;
  color: #D7BCE7;
}

.Proposer main .FormCadre .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 20px;
}

.Proposer main .FormCadre .row1 input {
  height: 50px;
  font-family: 'Kodchasan';
}

.Proposer main .FormCadre .row1 input::-webkit-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  text-align: center;
}

.Proposer main .FormCadre .row1 input:-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  text-align: center;
}

.Proposer main .FormCadre .row1 input::-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  text-align: center;
}

.Proposer main .FormCadre .row1 input::placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  text-align: center;
}

.Proposer main .FormCadre .row1 .indiceAccess {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation p {
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}

.Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation .icon {
  position: absolute;
  right: -30px;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation .icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation:first-child {
  color: #D7BCE7;
}

.Proposer main .FormCadre .row2 .selectorCate {
  width: 50%;
}

.Proposer main .FormCadre .row2 p {
  width: 50%;
}

.Proposer main .FormCadre .row3 .datePicker {
  color: #D7BCE7;
}

.Proposer main .FormCadre .row3 .DatePickerCustom .datePickerPopUp {
  display: block;
  position: fixed;
  color: #D7BCE7;
  background-color: #386781;
  z-index: 2;
  border-width: 2px;
  border-style: double;
  border-radius: 20px;
  border-color: #D7BCE7;
}

.Proposer main .FormCadre .row3 .DatePickerCustom .dateAffichage {
  margin: 2px;
  font-size: 14px;
  color: white;
  text-align: center;
}

.Proposer main .FormCadre .row3 .hourPicker {
  position: fixed;
  right: 20%;
  background-color: #386781;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-width: 1px;
  border-color: #D7BCE7;
  border-style: double;
  border-radius: 20px;
  padding-bottom: 20px;
  margin-left: 20px;
  z-index: 2;
  font-family: 'Kodchasan';
}

.Proposer main .FormCadre .row3 .hourPicker .hour {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  text-align: center;
}

.Proposer main .FormCadre .row3 .hourPicker .hour .validHour {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #D7BCE7;
  color: #D7BCE7;
}

.Proposer main .FormCadre .row3 .hourPicker input {
  width: 10%;
  font-size: 15px;
  padding: 0px;
}

.Proposer main .FormCadre .row3 .hourPicker .erreur {
  font-size: 12px;
  font-family: 'Kodchasan';
  margin: 0;
  -webkit-animation: error 0.5s forwards;
          animation: error 0.5s forwards;
}

.Proposer main .FormCadre .row3 .hourPicker .close {
  background-color: transparent;
  padding: 0px;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.Proposer main .FormCadre .row3 .hourPicker .close:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Proposer main .FormCadre .row3 .hourPicker .close .circle {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-style: double;
  border-width: 2px;
  border-color: #D7BCE7;
}

.Proposer main .FormCadre .row3 .hourPicker .close .circle .trait1 {
  position: absolute;
  height: 10px;
  width: 2px;
  background-color: #D7BCE7;
  margin-left: 45%;
  top: 20%;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.Proposer main .FormCadre .row3 .hourPicker .close .circle .trait2 {
  position: absolute;
  height: 10px;
  width: 2px;
  background-color: #D7BCE7;
  margin-left: 45%;
  top: 20%;
  -webkit-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}

.Proposer main .FormCadre .row3 .datePicker {
  z-index: 2;
  background-color: transparent;
  border: none;
  font-family: 'Kodchasan';
}

.Proposer main .FormCadre .row3 .datePicker p {
  margin: 0;
}

.Proposer main .FormCadre .row3 .heure {
  font-family: 14px;
  margin: 0;
  color: white;
  text-align: center;
}

.Proposer main .FormCadre .row4 {
  margin-top: 20px;
}

.Proposer main .FormCadre .row4 ::-webkit-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row4 :-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row4 ::-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row4 ::placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row4 .cout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Proposer main .FormCadre .row4 .cout input {
  width: 100%;
}

.Proposer main .FormCadre .row4 .cout img {
  margin-left: 5px;
  width: 20px;
}

.Proposer main .FormCadre .row4 .cout .popUp {
  margin-right: 20%;
  position: fixed;
  right: auto;
  left: auto;
  -webkit-animation: moove 1s;
          animation: moove 1s;
  background-color: #386781;
  padding: 10px;
  border-radius: 20px;
  border-width: 2px;
  border-style: double;
  border-color: #D7BCE7;
  z-index: 2;
}

.Proposer main .FormCadre .row4 .cout .popUp .policier {
  opacity: 0;
  margin-left: 50%;
  -webkit-animation: policier 1s forwards;
          animation: policier 1s forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  width: 50px;
}

.Proposer main .FormCadre .row4 .cout .popUp p {
  font-family: 'Kodchasan';
  color: #D7BCE7;
}

.Proposer main .FormCadre .row4 .cout .popUp .accord {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Proposer main .FormCadre .row4 .cout .popUp .accord button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #afffaf;
  color: #386781;
  font-family: 'Kodchasan';
  border: none;
  font-weight: 900;
  padding: 10px;
  border-radius: 10px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.Proposer main .FormCadre .row4 .cout .popUp .accord button .compris {
  -webkit-transition: all 500ms;
  transition: all 500ms;
  color: #386781;
  margin: 0px;
}

.Proposer main .FormCadre .row4 .cout .popUp .accord button:hover {
  background-color: #49a249;
}

.Proposer main .FormCadre .row4 .cout .popUp .accord button:hover .compris {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Proposer main .FormCadre .row4 .cout .popUp .accord button .pouce {
  margin-left: 5px;
  -webkit-animation: pouce 2s infinite;
          animation: pouce 2s infinite;
}

.Proposer main .FormCadre .row5 {
  margin-top: 20px;
}

.Proposer main .FormCadre .row5 .placeDispo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Proposer main .FormCadre .row5 .placeDispo ::-webkit-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row5 .placeDispo :-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row5 .placeDispo ::-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row5 .placeDispo ::placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row5 .placeDispo img {
  z-index: 0;
  margin-left: 5px;
  width: 30px;
  -webkit-animation: voiture 200ms infinite;
          animation: voiture 200ms infinite;
}

.Proposer main .FormCadre .row6 {
  margin-top: 20px;
}

.Proposer main .FormCadre .row6 ::-webkit-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row6 :-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row6 ::-ms-input-placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row6 ::placeholder {
  color: #386781;
  font-family: 'Kodchasan';
  opacity: 0.7;
  font-weight: 900;
}

.Proposer main .FormCadre .row6 input {
  font-family: 'Kodchasan';
}

.Proposer main .FormCadre .row6 input:focus-visible {
  border: none;
  outline: 0cm;
}

.Proposer main .FormCadre .row7 .conditions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Proposer main .FormCadre .row8 {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Proposer main .FormCadre .row8 button {
  background-color: #D7BCE7;
  font-family: 'Kodchasan';
  font-size: 20px;
  border-width: 0px;
  color: #386781;
  margin-bottom: 20px;
}

.Proposer main .FormCadre .row8 .error {
  text-align: center;
  margin: 0px;
  color: orange;
  font-size: 12px;
  -webkit-animation: error 1s forwards;
          animation: error 1s forwards;
  font-family: 'Kodchasan';
}

@media screen and (max-width: 900px) {
  .Proposer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #D7BCE7;
  }
  .Proposer main {
    padding-left: 0%;
    margin-bottom: 100px;
    width: 100%;
  }
  .Proposer main .FormCadre {
    padding-top: 20px;
    background-color: #386781;
    border-radius: 0px;
    margin: 0px;
    margin-top: 40px;
    padding-left: 5%;
    padding-right: 5%;
    color: #D7BCE7;
  }
  .Proposer main .FormCadre .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 20px;
  }
  .Proposer main .FormCadre .row1 input {
    height: 50px;
    font-family: 'Kodchasan';
    margin-bottom: 30px;
  }
  .Proposer main .FormCadre .row1 input::-webkit-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    text-align: center;
  }
  .Proposer main .FormCadre .row1 input:-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    text-align: center;
  }
  .Proposer main .FormCadre .row1 input::-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    text-align: center;
  }
  .Proposer main .FormCadre .row1 input::placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    text-align: center;
  }
  .Proposer main .FormCadre .row1 .indiceAccess {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }
  .Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation p {
    padding: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
    font-size: 20px;
  }
  .Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation .icon {
    position: absolute;
    right: -30px;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation .icon:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .Proposer main .FormCadre .row1 .indiceAccess .titreEtInterogation:first-child {
    color: #D7BCE7;
  }
  .Proposer main .FormCadre .row2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .Proposer main .FormCadre .row2 .selectorCate {
    width: 100%;
  }
  .Proposer main .FormCadre .row2 p {
    width: auto;
  }
  .Proposer main .FormCadre .row3 .datePicker {
    color: #D7BCE7;
    width: 100%;
    padding: 10px;
    text-align: center;
    border-style: solid;
    border-color: #D7BCE7;
    border-width: 1px;
    padding: 5px;
    margin-top: 1.5em;
  }
  .Proposer main .FormCadre .row3 .DatePickerCustom {
    width: 100%;
    position: relative;
  }
  .Proposer main .FormCadre .row3 .DatePickerCustom .datePickerPopUp {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #D7BCE7;
    background-color: #386781;
    z-index: 2;
    border-width: 2px;
    border-style: double;
    border-radius: 20px;
    border-color: #D7BCE7;
  }
  .Proposer main .FormCadre .row3 .DatePickerCustom .dateAffichage {
    margin: 2px;
    font-size: 18px;
    color: white;
    text-align: center;
  }
  .Proposer main .FormCadre .row3 .hourPicker {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: fixed;
    background-color: #386781;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-width: 1px;
    border-color: #D7BCE7;
    border-style: double;
    border-radius: 20px;
    padding-bottom: 20px;
    margin-left: 0px;
    z-index: 2;
    font-family: 'Kodchasan';
  }
  .Proposer main .FormCadre .row3 .hourPicker .hour {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    text-align: center;
  }
  .Proposer main .FormCadre .row3 .hourPicker .hour input {
    font-size: 20px;
  }
  .Proposer main .FormCadre .row3 .hourPicker .hour .validHour {
    background-color: transparent;
    border-width: 0px;
    font-size: 15px;
  }
  .Proposer main .FormCadre .row3 .hourPicker .hour .validHour p {
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    text-align: end;
    border-color: #D7BCE7;
    color: #D7BCE7;
  }
  .Proposer main .FormCadre .row3 .hourPicker input {
    width: 10%;
  }
  .Proposer main .FormCadre .row3 .hourPicker .erreur {
    font-size: 12px;
    font-family: 'Kodchasan';
    margin: 0;
    -webkit-animation: error 0.5s forwards;
            animation: error 0.5s forwards;
  }
  .Proposer main .FormCadre .row3 .hourPicker .close {
    background-color: transparent;
    padding: 0px;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .Proposer main .FormCadre .row3 .hourPicker .close:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .Proposer main .FormCadre .row3 .hourPicker .close .circle {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-style: double;
    border-width: 2px;
    border-color: #D7BCE7;
  }
  .Proposer main .FormCadre .row3 .hourPicker .close .circle .trait1 {
    position: absolute;
    height: 10px;
    width: 2px;
    background-color: #D7BCE7;
    margin-left: 45%;
    top: 20%;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }
  .Proposer main .FormCadre .row3 .hourPicker .close .circle .trait2 {
    position: absolute;
    height: 10px;
    width: 2px;
    background-color: #D7BCE7;
    margin-left: 45%;
    top: 20%;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg);
  }
  .Proposer main .FormCadre .row3 .heure {
    font-family: 14px;
    margin: 0;
    color: white;
    text-align: center;
  }
  .Proposer main .FormCadre .row4 {
    margin-top: 20px;
  }
  .Proposer main .FormCadre .row4 ::-webkit-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row4 :-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row4 ::-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row4 ::placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row4 .cout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Proposer main .FormCadre .row4 .cout input {
    width: 100%;
    position: relative;
    font-size: 20px;
    text-align: center;
    color: #386781;
    font-family: 'Kodchasan';
  }
  .Proposer main .FormCadre .row4 .cout img {
    position: absolute;
    width: 20px;
    right: 0;
  }
  .Proposer main .FormCadre .row4 .cout .popUp {
    margin-right: 0%;
    position: absolute;
    right: auto;
    left: auto;
    -webkit-animation: moove 1s;
            animation: moove 1s;
    background-color: #386781;
    padding: 10px;
    border-radius: 20px;
    border-width: 2px;
    border-style: double;
    border-color: #D7BCE7;
    z-index: 2;
  }
  .Proposer main .FormCadre .row4 .cout .popUp .policier {
    opacity: 0;
    margin-left: 45%;
    -webkit-animation: policier 1s forwards;
            animation: policier 1s forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    width: 50px;
  }
  .Proposer main .FormCadre .row4 .cout .popUp p {
    font-family: 'Kodchasan';
    color: #D7BCE7;
  }
  .Proposer main .FormCadre .row4 .cout .popUp .accord {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .Proposer main .FormCadre .row4 .cout .popUp .accord button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    background-color: #afffaf;
    color: #386781;
    font-family: 'Kodchasan';
    border: none;
    font-weight: 900;
    padding: 10px;
    border-radius: 10px;
    -webkit-transition: all 500ms;
    transition: all 500ms;
  }
  .Proposer main .FormCadre .row4 .cout .popUp .accord button .compris {
    -webkit-transition: all 500ms;
    transition: all 500ms;
    color: #386781;
    margin: 0px;
  }
  .Proposer main .FormCadre .row4 .cout .popUp .accord button:hover {
    background-color: #49a249;
  }
  .Proposer main .FormCadre .row4 .cout .popUp .accord button:hover .compris {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .Proposer main .FormCadre .row4 .cout .popUp .accord button .pouce {
    margin-left: 5px;
    -webkit-animation: pouce 2s infinite;
            animation: pouce 2s infinite;
  }
  .Proposer main .FormCadre .row5 {
    margin-top: 20px;
  }
  .Proposer main .FormCadre .row5 .placeDispo {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Proposer main .FormCadre .row5 .placeDispo ::-webkit-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row5 .placeDispo :-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row5 .placeDispo ::-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row5 .placeDispo ::placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row5 .placeDispo input {
    font-family: 'Kodchasan';
    width: 85%;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    color: #386781;
  }
  .Proposer main .FormCadre .row5 .placeDispo input ::-webkit-input-placeholder {
    font-family: 'Kodchasan';
  }
  .Proposer main .FormCadre .row5 .placeDispo input :-ms-input-placeholder {
    font-family: 'Kodchasan';
  }
  .Proposer main .FormCadre .row5 .placeDispo input ::-ms-input-placeholder {
    font-family: 'Kodchasan';
  }
  .Proposer main .FormCadre .row5 .placeDispo input ::placeholder {
    font-family: 'Kodchasan';
  }
  .Proposer main .FormCadre .row5 .placeDispo img {
    position: absolute;
    z-index: 0;
    width: 30px;
    -webkit-animation: voiture 200ms infinite;
            animation: voiture 200ms infinite;
    right: 0;
  }
  .Proposer main .FormCadre .row6 {
    margin-top: 20px;
  }
  .Proposer main .FormCadre .row6 ::-webkit-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row6 :-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row6 ::-ms-input-placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row6 ::placeholder {
    color: #386781;
    font-family: 'Kodchasan';
    opacity: 0.7;
    font-weight: 900;
  }
  .Proposer main .FormCadre .row6 input {
    font-family: 'Kodchasan';
    font-size: 15px;
    padding: 5px;
    text-align: center;
    color: #386781;
  }
  .Proposer main .FormCadre .row6 input:focus-visible {
    border: none;
    outline: 0cm;
  }
  .Proposer main .FormCadre .row7 .conditions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .Proposer main .FormCadre .row8 {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .Proposer main .FormCadre .row8 button {
    background-color: #D7BCE7;
    font-family: 'Kodchasan';
    font-size: 20px;
    border-width: 0px;
    color: #386781;
    margin-bottom: 20px;
  }
  .Proposer main .FormCadre .row8 .error {
    text-align: center;
    margin: 0px;
    color: orange;
    font-size: 12px;
    -webkit-animation: error 1s forwards;
            animation: error 1s forwards;
    font-family: 'Kodchasan';
  }
}

@-webkit-keyframes moove {
  0% {
    -webkit-transform: translate(-300px);
            transform: translate(-300px);
    opacity: 0.0;
  }
  100% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
    opacity: 1;
  }
}

@keyframes moove {
  0% {
    -webkit-transform: translate(-300px);
            transform: translate(-300px);
    opacity: 0.0;
  }
  100% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
    opacity: 1;
  }
}

@-webkit-keyframes pouce {
  0% {
  }
  50% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
  80% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pouce {
  0% {
  }
  50% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
  80% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes voiture {
  0% {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }
  50% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
  }
  100% {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }
}

@keyframes voiture {
  0% {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }
  50% {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
  }
  100% {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }
}

@-webkit-keyframes policier {
  0% {
    -webkit-transform: translate(300px);
            transform: translate(300px);
    opacity: 0.0;
  }
  50% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
    opacity: 1.1;
  }
  70% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes policier {
  0% {
    -webkit-transform: translate(300px);
            transform: translate(300px);
    opacity: 0.0;
  }
  50% {
    -webkit-transform: translate(0px);
            transform: translate(0px);
    opacity: 1.1;
  }
  70% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes validate {
  0% {
  }
  30% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes validate {
  0% {
  }
  30% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes error {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-103px);
            transform: translateX(-103px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes error {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-103px);
            transform: translateX(-103px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes error {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  45% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
