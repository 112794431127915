@font-face {
  font-family: 'Kodshasan';
  src: url("../../assets/fonts/Kodchasan-Light.ttf");
}

.MesEscapades {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.MesEscapades main {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #D7BCE7;
  padding-left: 15%;
}

.MesEscapades main .lesVignettes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  position: relative;
}

.MesEscapades main .lesVignettes .popUp {
  z-index: 2;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.519);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.MesEscapades main .lesVignettes .popUp .contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #386781;
  color: white;
  padding: 10%;
  width: 100%;
}

.MesEscapades main .lesVignettes .popUp .contain p {
  width: 50%;
}

.MesEscapades main .lesVignettes .popUp .contain .telephone {
  background-color: transparent;
  border-width: 0px;
  color: white;
  font-family: 'Kodshasan';
  font-size: 20px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.MesEscapades main .lesVignettes .popUp .contain .telephone:hover {
  background-color: aliceblue;
  border-radius: 20px;
}

.MesEscapades main .lesVignettes .popUp .contain .telephone:hover p {
  color: #386781;
}

.MesEscapades main .lesVignettes .popUp .contain .telephone:hover img {
  -webkit-transform: translate(20px);
          transform: translate(20px);
}

.MesEscapades main .lesVignettes .popUp .contain .telephone a {
  display: block;
}

.MesEscapades main .lesVignettes .popUp .contain .telephone p {
  width: 100%;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.MesEscapades main .lesVignettes .popUp .contain .telephone img {
  width: 20px;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.MesEscapades main .lesVignettes .popUp .contain .Fermer {
  margin-top: 20px;
  color: aliceblue;
  background-color: transparent;
  width: 140px;
  font-family: 'Kodshasan';
  border-width: 0px;
  background-color: green;
  font-size: 20px;
  padding: 10px;
  margin-left: 10%;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.MesEscapades main .lesVignettes .popUp .contain .Fermer:hover {
  background-color: #036203;
}

@media screen and (max-width: 900px) {
  .MesEscapades {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .MesEscapades main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #D7BCE7;
    padding-left: 0%;
  }
  .MesEscapades main .lesVignettes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    position: relative;
  }
  .MesEscapades main .lesVignettes .popUp {
    z-index: 2;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.519);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .MesEscapades main .lesVignettes .popUp .contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #386781;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: white;
    padding: 0%;
    width: 100%;
  }
  .MesEscapades main .lesVignettes .popUp .contain h2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MesEscapades main .lesVignettes .popUp .contain p {
    width: 100%;
    text-align: center;
  }
  .MesEscapades main .lesVignettes .popUp .contain a {
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }
  .MesEscapades main .lesVignettes .popUp .contain .telephone {
    background-color: transparent;
    border-width: 0px;
    color: white;
    font-family: 'Kodshasan';
    font-size: 20px;
    -webkit-transition: all 500ms;
    transition: all 500ms;
  }
  .MesEscapades main .lesVignettes .popUp .contain .telephone:hover {
    background-color: aliceblue;
    border-radius: 20px;
  }
  .MesEscapades main .lesVignettes .popUp .contain .telephone:hover p {
    color: #386781;
  }
  .MesEscapades main .lesVignettes .popUp .contain .telephone:hover img {
    -webkit-transform: translate(20px);
            transform: translate(20px);
  }
  .MesEscapades main .lesVignettes .popUp .contain .telephone a {
    display: block;
  }
  .MesEscapades main .lesVignettes .popUp .contain .telephone p {
    width: 100%;
    -webkit-transition: all 500ms;
    transition: all 500ms;
  }
  .MesEscapades main .lesVignettes .popUp .contain .telephone img {
    width: 20px;
    -webkit-transition: all 500ms;
    transition: all 500ms;
  }
  .MesEscapades main .lesVignettes .popUp .contain .Fermer {
    margin-top: 0px;
    color: aliceblue;
    background-color: transparent;
    width: 140px;
    font-family: 'Kodshasan';
    border-width: 0px;
    background-color: green;
    font-size: 20px;
    padding: 10px;
    margin-left: 0%;
    -webkit-transition: all 500ms;
    transition: all 500ms;
  }
  .MesEscapades main .lesVignettes .popUp .contain .Fermer:hover {
    background-color: #036203;
  }
}
