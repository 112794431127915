@font-face {
  font-family: 'Kodchasan';
  src: url("../../assets/fonts/Kodchasan-Light.ttf");
}

.NavigationBar {
  position: fixed;
  height: 100%;
  background-color: #386781;
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  max-width: 200px;
  z-index: 2;
}

.NavigationBar button {
  background-color: transparent;
  border: none;
}

.NavigationBar button p {
  font-family: 'Kodchasan';
  color: #D7BCE7;
}

.NavigationBar button img {
  width: 20%;
  min-width: 25px;
}

@media screen and (max-width: 900px) {
  .NavigationBar {
    position: fixed;
    height: 100%;
    background-color: #386781;
    width: 15%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    max-width: 200px;
    z-index: 2;
    display: none;
  }
  .NavigationBar button {
    background-color: transparent;
    border: none;
  }
  .NavigationBar button p {
    font-family: 'Kodchasan';
    color: #D7BCE7;
  }
  .NavigationBar button img {
    width: 20%;
    min-width: 25px;
  }
}
