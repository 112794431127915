@import '../../../style.scss';
@font-face {
    font-family: 'Kodchasan';
    src: url('../../../assets/fonts/Kodchasan-Light.ttf');
}

.selectorCate{
    
    .logo{
        margin-left: 20px;
        color: $violet;
    }
    select{
        color:$bleu;
        text-align: center;
        font-family: 'Kodchasan';
    }
    .infoCat{
        color: $violet;
        margin-left: 10px;
        p{
            font-size: 12px;
            margin: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        label{
            font-size: 12px;
        }
    }

}
@media screen and (max-width:900px) {

.selectorCate{
    width: 100%;
    p{
        text-align: center;
    }

    
    .logo{
        margin-left: 20px;
        color: $violet;
    }
    select{
        color:$bleu;
        text-align: center;
        font-family: 'Kodchasan';
        font-size: 15px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .infoCat{
        color: $violet;
        margin-left: 10px;
        p{
            font-size: 15px;
            margin: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        label{
            font-size: 12px;
        }
    }

}
    
}