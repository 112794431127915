@font-face {
  font-family: 'Genos';
  src: url("../../assets/fonts/Genos-VariableFont_wght.ttf");
}

header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #D7BCE7;
}

header .logOut {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  border-width: 0px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

header .logOut:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

header .logOut:hover img {
  -webkit-animation: logOut 0.5s  forwards;
          animation: logOut 0.5s  forwards;
}

header .logOut p {
  font-family: 'Kodchasan';
}

header .logOut img {
  margin-left: 10px;
  width: 10px;
}

header .backButton {
  display: none;
}

header h1 {
  color: #386781;
  font-family: 'Genos';
  z-index: 2;
}

header .logo {
  right: 0;
  background-color: transparent;
  border: none;
  width: 20%;
  height: 100px;
  max-width: 200px;
  background-image: url("../../assets/logo/LOGO1.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

@media screen and (max-width: 900px) {
  header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #D7BCE7;
    position: relative;
  }
  header .logOut {
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
    border-width: 0px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  header .logOut:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  header .logOut:hover img {
    -webkit-animation: logOut 0.5s  forwards;
            animation: logOut 0.5s  forwards;
  }
  header .logOut p {
    font-family: 'Kodchasan';
  }
  header .logOut img {
    margin-left: 10px;
    width: 10px;
  }
  header .backButton {
    display: block;
    position: absolute;
    top: 0px;
    left: 5px;
    z-index: 5;
  }
  header .backButton .back {
    height: 50px;
    width: 50px;
  }
  header h1 {
    margin-top: 60px;
    text-align: center;
    color: #386781;
    font-family: 'Genos';
    z-index: 2;
    padding-left: 20px;
  }
  header .logo {
    display: none;
    right: 0;
    background-color: transparent;
    border: none;
    width: 20%;
    min-width: 200px;
    height: 100px;
    max-width: 200px;
    background-image: url("../../assets/logo/LOGO1.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
}

@-webkit-keyframes logOut {
  0% {
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@keyframes logOut {
  0% {
  }
  100% {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}
