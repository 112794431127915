@font-face {
  font-family: 'Kodchasan';
  src: url("../../assets/fonts/Kodchasan-Light.ttf");
}

.LogIn {
  font-family: 'Kodchasan';
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #386781;
  padding-top: 5%;
  position: fixed;
}

.LogIn main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50%;
  height: 80%;
  min-height: 350px;
  background-color: #D7BCE7;
  border-radius: 20px;
  overflow: hidden;
}

.LogIn main .phraseError {
  font-size: 12px;
  color: red;
}

.LogIn main .logo {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  border-width: 0px;
  max-width: 150px;
  width: 200px;
}

.LogIn main .logo img {
  opacity: 0;
  -webkit-transform: translate(-200px);
          transform: translate(-200px);
  margin-top: 20px;
  width: 100%;
  -webkit-animation: logo 1s 500ms forwards;
          animation: logo 1s 500ms forwards;
}

.LogIn main ::-webkit-input-placeholder {
  color: #D7BCE7;
  text-align: center;
  font-family: 'Kodchasan';
}

.LogIn main :-ms-input-placeholder {
  color: #D7BCE7;
  text-align: center;
  font-family: 'Kodchasan';
}

.LogIn main ::-ms-input-placeholder {
  color: #D7BCE7;
  text-align: center;
  font-family: 'Kodchasan';
}

.LogIn main ::placeholder {
  color: #D7BCE7;
  text-align: center;
  font-family: 'Kodchasan';
}

.LogIn main ::content {
  color: white;
}

.LogIn main .email {
  margin-top: 20px;
  background-color: #386781;
  width: 80%;
  border: none;
  height: 40px;
  max-width: 400px;
  color: #D7BCE7;
  font-family: 'Kodchasan';
  font-size: 20px;
}

.LogIn main .motDePasse {
  margin-top: 20px;
  background-color: #386781;
  width: 80%;
  border: none;
  height: 40px;
  max-width: 400px;
  color: #D7BCE7;
  font-family: 'Kodchasan';
  font-size: 20px;
}

.LogIn main .motDePasseOublie {
  text-align: start;
  color: #386781;
  font-size: 12px;
}

.LogIn main .submit {
  margin-bottom: 20px;
  padding: 10px;
  border-style: double;
  border-width: 2px;
  border-color: #386781;
  background-color: transparent;
  font-size: 20px;
  font-family: 'Kodchasan';
  color: #386781;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.LogIn main .submit:hover {
  background-color: #386781;
  color: #D7BCE7;
}

.LogIn .waiting {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #D7BCE7;
  border-radius: 20px;
  height: 200px;
}

.LogIn .waiting img {
  width: 50%;
  max-width: 400px;
}

.LogIn .waiting .waitingRoute {
  position: relative;
}

.LogIn .waiting .waitingRoute img {
  width: 10%;
  -webkit-animation: vieux 20s infinite linear;
          animation: vieux 20s infinite linear;
}

.LogIn .waiting p {
  color: #386781;
  -webkit-animation: connection 1s infinite;
          animation: connection 1s infinite;
}

@media (max-width: 900px) {
  .LogIn {
    font-family: 'Kodchasan';
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 0%;
    position: fixed;
  }
  .LogIn main {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .LogIn .waiting {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #D7BCE7;
    border-radius: 0px;
    width: 100%;
  }
  .LogIn .waiting img {
    width: 50%;
    max-width: 400px;
  }
  .LogIn .waiting p {
    color: #386781;
    -webkit-animation: connection 1s infinite;
            animation: connection 1s infinite;
  }
}

@-webkit-keyframes logo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px);
            transform: translate(0px);
  }
}

@keyframes logo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px);
            transform: translate(0px);
  }
}

@-webkit-keyframes connection {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  30% {
    opacity: 0.7;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes connection {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  30% {
    opacity: 0.7;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
