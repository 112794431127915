@import '../../style.scss';
@font-face {
    font-family: 'Kodchasan';
    src: url('../../assets/fonts/Kodchasan-Light.ttf');
}

.Proposer {
    display: flex;
    flex-direction: column;
    background-color: $violet;
    main {
        padding-left: 15%;
        margin-bottom: 100px;
        .FormCadre {
            padding-top: 20px;
            background-color: $bleu;
            border-radius: 5px;
            margin: 10px;
            margin-top: 40px;
            padding-left: 5%;
            padding-right: 5%;
            color: $violet;
            .row {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
            }
            .row1 {
                input {
                    height: 50px;
                    font-family: 'Kodchasan';
                }
                input::placeholder {
                    color: $bleu;
                    font-family: 'Kodchasan';
                    text-align: center;
                }
                //indiceAccess
                .indiceAccess {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .titreEtInterogation {
                        display: flex;
                        position: relative;
                        p {
                            padding: 10px;
                            padding-bottom: 0px;
                            margin-bottom: 0px;
                            padding-top: 0px;
                        }
                        .icon {
                            position: absolute;
                            right: -30px;
                            transition: all 300ms;
                            &:hover {
                                transform: scale(1.2);
                            }
                        }
                    }
                    .titreEtInterogation:first-child {
                        color: $violet;
                    }
                }
            }
            .row2 {
                .selectorCate {
                    width: 50%;
                }
                p {
                    width: 50%;
                }
            }
            .row3 {
                .datePicker {
                    color: $violet;
                }
                .DatePickerCustom {
                    .datePickerPopUp {
                        display: block;
                        position: fixed;
                        color: $violet;
                        background-color: $bleu;
                        z-index: 2;
                        border-width: 2px;
                        border-style: double;
                        border-radius: 20px;
                        border-color: $violet;
                    }
                    .dateAffichage {
                        margin: 2px;
                        font-size: 14px;
                        color: white;
                        text-align: center;
                    }
                }
                .hourPicker {
                    position: fixed;
                    right:20%;
                    background-color: $bleu;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-width: 1px;
                    border-color: $violet;
                    border-style: double;
                    border-radius: 20px;
                    padding-bottom: 20px;
                    margin-left: 20px;
                    z-index: 2;
                    font-family: 'Kodchasan';
                    .hour {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        text-align: center;
                        .validHour {
                            background-color: transparent;
                            border-width: 1px;
                            border-style: solid;
                            border-color: $violet;
                            color: $violet;
                            
                        }
                    }
                    input {
                        width: 10%;
                        font-size: 15px;
                        padding: 0px;
                    }
                    .erreur {
                        font-size: 12px;
                        font-family: 'Kodchasan';
                        margin: 0;
                        animation: error 0.5s forwards;
                    }
                    .close {
                        background-color: transparent;
                        padding: 0px;
                        border: none;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.5s;
                        &:hover {
                            transform: scale(1.1);
                        }
                        .circle {
                            position: relative;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            border-style: double;
                            border-width: 2px;
                            border-color: $violet;
                            .trait1 {
                                position: absolute;
                                height: 10px;
                                width: 2px;
                                background-color: $violet;
                                margin-left: 45%;
                                top: 20%;
                                transform: rotateZ(45deg);
                            }
                            .trait2 {
                                position: absolute;
                                height: 10px;
                                width: 2px;
                                background-color: $violet;
                                margin-left: 45%;
                                top: 20%;
                                transform: rotateZ(-45deg);
                            }
                        }
                    }
                }
                .datePicker {
                    z-index: 2;
                    background-color: transparent;
                    border: none;
                    font-family: 'Kodchasan';
                    p {
                        margin: 0;
                    }
                }
                .heure {
                    font-family: 14px;
                    margin: 0;
                    color: white;
                    text-align: center;
                }
            }
            .row4 {
                 ::placeholder {
                    color: $bleu;
                    font-family: 'Kodchasan';
                    opacity: 0.7;
                    font-weight: 900;
                }
                margin-top: 20px;
                .cout {
                    display: flex;
                    input {
                        width: 100%;
                    }
                    img {
                        margin-left: 5px;
                        width: 20px;
                    }
                    .popUp {
                        margin-right: 20%;
                        position: fixed;
                        right: auto;
                        left: auto;
                        animation: moove 1s;
                        background-color: $bleu;
                        padding: 10px;
                        border-radius: 20px;
                        border-width: 2px;
                        border-style: double;
                        border-color: $violet;
                        z-index: 2;
                        .policier {
                            opacity: 0;
                            margin-left: 50%;
                            animation: policier 1s forwards;
                            animation-delay: 1s;
                            width: 50px;
                        }
                        p {
                            font-family: 'Kodchasan';
                            color: $violet;
                        }
                        .accord {
                            display: flex;
                            justify-content: center;
                            button {
                                display: flex;
                                flex-direction: row;
                                background-color: rgb(175, 255, 175);
                                color: $bleu;
                                font-family: 'Kodchasan';
                                border: none;
                                font-weight: 900;
                                padding: 10px;
                                border-radius: 10px;
                                transition: all 500ms;
                                .compris {
                                    transition: all 500ms;
                                    color: $bleu;
                                    margin: 0px;
                                }
                                &:hover {
                                    background-color: rgb(73, 162, 73);
                                    .compris {
                                        transform: scale(1.1);
                                    }
                                }
                                .pouce {
                                    margin-left: 5px;
                                    animation: pouce 2s infinite;
                                }
                            }
                        }
                    }
                }
            }
            .row5 {
                margin-top: 20px;
                .placeDispo {
                    display: flex;
                     ::placeholder {
                        color: $bleu;
                        font-family: 'Kodchasan';
                        opacity: 0.7;
                        font-weight: 900;
                    }
                    img {
                        z-index: 0;
                        margin-left: 5px;
                        width: 30px;
                        animation: voiture 200ms infinite;
                    }
                }
            }
            .row6 {
                margin-top: 20px;
                 ::placeholder {
                    color: $bleu;
                    font-family: 'Kodchasan';
                    opacity: 0.7;
                    font-weight: 900;
                }
                input {
                    font-family: 'Kodchasan';
                    &:focus-visible {
                        border: none;
                        outline: 0cm;
                    }
                }
            }
            .row7 {
                .conditions {
                    display: flex;
                }
            }
            .row8 {
                justify-content: end;
                align-items: center;
                button {
                    background-color: $violet;
                    font-family: 'Kodchasan';
                    font-size: 20px;
                    border-width: 0px;
                    color: $bleu;
                    margin-bottom: 20px;
                }
                .error {
                    text-align: center;
                    margin: 0px;
                    color: orange;
                    font-size: 12px;
                    animation: error 1s forwards;
                    font-family: 'Kodchasan';
                }
            }
        }
    }
}


@media screen and (max-width:900px) {
    .Proposer {
        display: flex;
        flex-direction: column;
        background-color: $violet;
        main {
            padding-left: 0%;
            margin-bottom: 100px;
            width: 100%;
            .FormCadre {
                padding-top: 20px;
                background-color: $bleu;
                border-radius: 0px;
                margin: 0px;
                margin-top: 40px;
                padding-left: 5%;
                padding-right: 5%;
                color: $violet;
                .row {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    margin-top: 20px;
                }
                .row1 {
                    input {
                        height: 50px;
                        font-family: 'Kodchasan';
                        margin-bottom: 30px;
                    }
                    input::placeholder {
                        color: $bleu;
                        font-family: 'Kodchasan';
                        text-align: center;
                    }
                    //indiceAccess
                    .indiceAccess {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .titreEtInterogation {
                            display: flex;
                            position: relative;
                            p {
                                padding: 10px;
                                padding-bottom: 0px;
                                margin-bottom: 0px;
                                margin-top: 0px;
                                padding-top: 0px;
                                font-size: 20px;
                            }
                            .icon {
                                position: absolute;
                                right: -30px;
                                transition: all 300ms;
                                &:hover {
                                    transform: scale(1.2);
                                }
                            }
                        }
                        .titreEtInterogation:first-child {
                            color: $violet;
                        }
                    }
                }
                .row2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .selectorCate {
                        width: 100%;
                    }
                    p {
                        width: auto;
                    }
                }
                .row3 {
                    .datePicker {
                        color: $violet;
                        width: 100%;
                        padding: 10px;
                        text-align: center;
                        border-style: solid;
                        border-color: $violet;
                        border-width: 1px;
                        padding: 5px;
                        margin-top: 1.5em;
                    }
                    .DatePickerCustom {
                        width: 100%;
                        
                        position: relative;
                        .datePickerPopUp {
                            display: block;
                            position: absolute;
                            top:50%;
                            left:50%;
                            transform: translate(-50%,-50%);
                            color: $violet;
                            background-color: $bleu;
                            z-index: 2;
                            border-width: 2px;
                            border-style: double;
                            border-radius: 20px;
                            border-color: $violet;
                        }
                        .dateAffichage {
                            margin: 2px;
                            font-size: 18px;
                            color: white;
                            text-align: center;
                        }
                    }
                    .hourPicker {
                        top:50%;
                        left:50%;
                        transform: translate(-50%,-50%);
                        position: fixed;
                        background-color: $bleu;
                        display: flex;
                        width: 90%;
                        flex-direction: column;
                        align-items: center;
                        border-width: 1px;
                        border-color: $violet;
                        border-style: double;
                        border-radius: 20px;
                        padding-bottom: 20px;
                        margin-left: 0px;
                        z-index: 2;
                        font-family: 'Kodchasan';
                        .hour {
                            display: flex;
                            justify-content: space-around;
                            text-align: center;
                            input{
                                font-size: 20px;
                            }
                            .validHour {
                                background-color: transparent;
                                border-width: 0px;
                                font-size: 15px;
                                p{
                                    padding: 10px;
                                    border-width: 1px;
                                    border-style: solid;
                                    text-align: end;
                                    border-color: $violet;
                                    color: $violet;
                                }
                                
                            }
                        }
                        input {
                            width: 10%;
                        }
                        .erreur {
                            font-size: 12px;
                            font-family: 'Kodchasan';
                            margin: 0;
                            animation: error 0.5s forwards;
                        }
                        .close {
                            background-color: transparent;
                            padding: 0px;
                            border: none;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.5s;
                            &:hover {
                                transform: scale(1.1);
                            }
                            .circle {
                                position: relative;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                border-style: double;
                                border-width: 2px;
                                border-color: $violet;
                                .trait1 {
                                    position: absolute;
                                    height: 10px;
                                    width: 2px;
                                    background-color: $violet;
                                    margin-left: 45%;
                                    top: 20%;
                                    transform: rotateZ(45deg);
                                }
                                .trait2 {
                                    position: absolute;
                                    height: 10px;
                                    width: 2px;
                                    background-color: $violet;
                                    margin-left: 45%;
                                    top: 20%;
                                    transform: rotateZ(-45deg);
                                }
                            }
                        }
                    }
                    .heure {
                        font-family: 14px;
                        margin: 0;
                        color: white;
                        text-align: center;
                    }
                }
                .row4 {
                     ::placeholder {
                        color: $bleu;
                        font-family: 'Kodchasan';
                        opacity: 0.7;
                        font-weight: 900;
                    }
                    margin-top: 20px;
                    .cout {
                        display: flex;
                        input {
                            width: 100%;
                            position: relative;
                            font-size: 20px;
                            text-align: center;
                            color:$bleu;
                            font-family: 'Kodchasan';
                        }
                        img {
                            position: absolute;
                            width: 20px;
                            right: 0;
                        }
                        .popUp {
                            margin-right: 0%;
                            position: absolute;
                            right: auto;
                            left: auto;
                            animation: moove 1s;
                            background-color: $bleu;
                            padding: 10px;
                            border-radius: 20px;
                            border-width: 2px;
                            border-style: double;
                            border-color: $violet;
                            z-index: 2;
                            .policier {
                                opacity: 0;
                                margin-left: 45%;
                                animation: policier 1s forwards;
                                animation-delay: 1s;
                                width: 50px;
                            }
                            p {
                                font-family: 'Kodchasan';
                                color: $violet;
                            }
                            .accord {
                                display: flex;
                                justify-content: center;
                                button {
                                    display: flex;
                                    flex-direction: row;
                                    background-color: rgb(175, 255, 175);
                                    color: $bleu;
                                    font-family: 'Kodchasan';
                                    border: none;
                                    font-weight: 900;
                                    padding: 10px;
                                    border-radius: 10px;
                                    transition: all 500ms;
                                    .compris {
                                        transition: all 500ms;
                                        color: $bleu;
                                        margin: 0px;
                                    }
                                    &:hover {
                                        background-color: rgb(73, 162, 73);
                                        .compris {
                                            transform: scale(1.1);
                                        }
                                    }
                                    .pouce {
                                        margin-left: 5px;
                                        animation: pouce 2s infinite;
                                    }
                                }
                            }
                        }
                    }
                }
                .row5 {
                    margin-top: 20px;
                    .placeDispo {
                        position: relative;
                        width: 100%;
                        display: flex;
                         ::placeholder {
                            color: $bleu;
                            font-family: 'Kodchasan';
                            opacity: 0.7;
                            font-weight: 900;
                        }
                        input{
                            font-family: 'Kodchasan';
                            width: 85%;
                            font-size: 15px;
                            text-align: center;
                            padding: 5px;
                            color: $bleu;
                            ::placeholder{
                                font-family: 'Kodchasan';

                            }
                        }
                        img {
                            position: absolute;
                            z-index: 0;
                            width: 30px;
                            animation: voiture 200ms infinite;
                            right: 0;
                        }
                    }
                }
                .row6 {
                    margin-top: 20px;
                     ::placeholder {
                        color: $bleu;
                        font-family: 'Kodchasan';
                        opacity: 0.7;
                        font-weight: 900;
                    }
                    input {
                        font-family: 'Kodchasan';
                        font-size: 15px;
                        padding: 5px;
                        text-align: center;
                        color: $bleu;

                        &:focus-visible {
                            border: none;
                            outline: 0cm;
                        }
                    }
                }
                .row7 {
                    .conditions {
                        display: flex;
                        flex-direction: column;
                    }
                }
                .row8 {
                    justify-content: end;
                    align-items: center;
                    button {
                        background-color: $violet;
                        font-family: 'Kodchasan';
                        font-size: 20px;
                        border-width: 0px;
                        color: $bleu;
                        margin-bottom: 20px;
                    }
                    .error {
                        text-align: center;
                        margin: 0px;
                        color: orange;
                        font-size: 12px;
                        animation: error 1s forwards;
                        font-family: 'Kodchasan';
                    }
                }
            }
        }
    }

    
}

@keyframes moove {
    0% {
        transform: translate(-300px);
        opacity: 0.0;
    }
    100% {
        transform: translate(00px);
        opacity: 1;
    }
}

@keyframes pouce {
    0% {}
    50% {
        transform: rotateZ(360deg);
    }
    80% {
        transform: rotateZ(360deg);
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes voiture {
    0% {
        transform: translateY(1px);
    }
    50% {
        transform: translateY(-1px);
    }
    100% {
        transform: translateY(1px);
    }
}

@keyframes policier {
    0% {
        transform: translate(300px);
        opacity: 0.0
    }
    50% {
        transform: translate(0px);
        opacity: 1.1
    }
    70% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes validate {
    0% {}
    30% {
        transform: rotate(-360deg);
    }
    50% {
        transform: scale(1.1);
    }
    90% {
        transform: scale(1);
    }
    100% {
        transform: rotate(-360deg);
        transform: scale(1);
    }
}

@keyframes error {
    0% {
        opacity: 0;
        transform: translateX(-103px);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes error {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(0.8);
    }
    40% {
        transform: scale(1.2);
    }
    45% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}