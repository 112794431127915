@import '../../style.scss';

.Calendrier{
        display: flex;
        flex-direction: column;
        main{
            display: flex;
            flex-direction: column;
            background-color: $violet;
            padding-left: 15%;
            .lesVignettes{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    

}
@media screen and (max-width:900px) {

.Calendrier{
    display: flex;
    flex-direction: column;
    main{
        display: flex;
        flex-direction: column;
        background-color: $violet;
        padding-left: 0%;
        .lesVignettes{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }


}
    
}
