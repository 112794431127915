.ForgotPassword {
  background-color: #fff;
  padding: 10px;
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
}

.ForgotPassword input {
  text-align: center;
  width: 50%;
  height: 40px  !important;
  font-size: 20px;
}

.ForgotPassword button {
  margin-top: 10px;
  width: 100%;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}
