.waiter {
  width: 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.535);
  z-index: 3;
}

.waiter .cadre {
  background-color: #D7BCE7;
  opacity: 1;
  padding: 45px;
  border-radius: 20px;
  width: 200px;
  margin-left: 30%;
  margin-right: auto;
  margin-top: 10%;
}

.waiter .cadre img {
  width: 200px;
  -webkit-animation: roll 5s infinite;
          animation: roll 5s infinite;
}

@-webkit-keyframes roll {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes roll {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
