@import '../../style.scss';
@font-face {
    font-family: 'Kodchasan';
    src: url('../../assets/fonts/Kodchasan-Light.ttf');
}

.Acceuil {
    margin-top: 0px;
    background-image: url('https://assets.papyhappy.com/FR/institution/covers/dc85e9ee931515f36750d4ad0770eec50a76e4dc.jpg');
    main {
        background-color: rgba(215, 188, 231, 0.9);
        margin-top: 0%;
        padding: 20px;
        color: $violet;
        width: 80%;
        text-align: center;
        font-family: 'Kodchasan';
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-left: 15%;
      
        h2 {}
    }
    .NavigationMobil{
        display: none;
    }
    .descriptionOrdinateur{
        display: block;
        width: 100%;
        height: 100%;
        margin-top: 10%;
        margin-bottom: 10%;
        color:black;
        
        background-size: cover;
        background-position: center;
        
        p{
            line-height: 2.5em;
        }
    }
}

@media screen and (max-width:900px) {
    .Acceuil {
        margin-top: 0px;
        main {
            margin-top: 0%;
            padding: 0px;
            color: $violet;
            width: 100%;
            text-align: center;
            font-family: 'Kodchasan';
            border-radius: 20px;
            margin-left: auto;
            margin-right: auto;
            margin-left: 0%;
            header {
                display: none;
            }
            h2 {}
            .NavigationMobil{
                display: block;
                .logoMobil{
                    
                    width: 100%;
                    img{
                        opacity: 0;
                        width: 50%;
                        animation: logoMobil 1s forwards;
                        border: 2px;
                    border-style: solid;
                    border-color: $bleu;
                    border-radius: 3px;
                    }
                }
                .proposer{
                    background-color: $bleu;
                    width: 100%;
                    font-size: 30px;
                }
                img{
                    margin-top: 10%;
                    margin-bottom: 10%;
                    width: 20%;
                }
            }
            .descriptionOrdinateur{
                display: none;
            }
        }
    }
}
@keyframes logoMobil {
    0%{
        transform: scale(0.3);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
    
}