@import '../../style.scss';
@font-face {
    font-family: 'Kodshasan';
    src: url('../../assets/fonts/Kodchasan-Light.ttf');
}
.MesEscapades {
    display: flex;
    flex-direction: column;
    main {
        display: grid;
        flex-direction: column;
        background-color: $violet;
        padding-left: 15%;
        .lesVignettes {
            display: flex;
            overflow: hidden;
            flex-wrap: wrap;
            justify-content: start;
            position: relative;
            .popUp{
                z-index: 2;
                position: fixed;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.519);
                display: flex;
                justify-content: center;
                .contain{
                    display: flex;
                    flex-direction: column;
                    background-color: $bleu;
                    color: white;
                    padding: 10%;
                    width: 100%;
                    p{
                        width: 50%;

                    }
                    .telephone{
                        background-color: transparent;
                        border-width: 0px;
                        color: white;
                        font-family: 'Kodshasan';
                        font-size: 20px;
                        transition: all 500ms;
                        &:hover{
                            background-color: aliceblue;
                            border-radius: 20px;
                            p{
                                color:$bleu
                            }
                            img{
                                transform: translate(+20px);
                            }
                        }
                        a{
                            display: block;
                        }
                        p{
                            width: 100%;
                            transition: all 500ms;
                            
                        }
                        img{
                            width: 20px;
                            transition: all 500ms;
                        }
                    }
                    .Fermer{
                        margin-top: 20px;
                        color: aliceblue;
                    background-color: transparent;
                    width: 140px;
                    font-family: 'Kodshasan';
                    border-width: 0px;
                    background-color: green;
                    font-size: 20px;
                    padding: 10px;
                    margin-left: 10%;
                    transition: all 500ms;
                    &:hover{
                        background-color: rgb(3, 98, 3);

                    }

                    }
                }
            }
        }
    }
}
@media screen and (max-width:900px) {
    .MesEscapades {
        display: flex;
        flex-direction: column;
        main {
            display: flex;
            flex-direction: column;
            background-color: $violet;
            padding-left: 0%;
            .lesVignettes {
                display: flex;
                overflow: hidden;
                flex-wrap: wrap;
                justify-content: start;
                position: relative;
                .popUp{
                    z-index: 2;
                    position: fixed;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.519);
                    display: flex;
                    justify-content: center;
                    .contain{
                        display: flex;
                        flex-direction: column;
                        background-color: $bleu;
                        align-items: center;
                        color: white;
                        padding: 0%;
                        width: 100%;
                        h2{
                            margin-top: 0px;
                            margin-bottom: 0px;
                        }
                        p{
                            width: 100%;
                            text-align: center;
    
                        }
                        a{
                            display: block;
                            display: flex;
                            justify-content: center;
                            text-decoration-line: none;
                        }
                        .telephone{
                            background-color: transparent;
                            border-width: 0px;
                            color: white;
                            font-family: 'Kodshasan';
                            font-size: 20px;
                            transition: all 500ms;
                            &:hover{
                                background-color: aliceblue;
                                border-radius: 20px;
                                p{
                                    color:$bleu
                                }
                                img{
                                    transform: translate(+20px);
                                }
                            }
                            a{
                                display: block;
                            }
                            p{
                                width: 100%;
                                transition: all 500ms;
                                
                            }
                            img{
                                width: 20px;
                                transition: all 500ms;
                            }
                        }
                        .Fermer{
                            margin-top: 0px;
                            color: aliceblue;
                        background-color: transparent;
                        width: 140px;
                        font-family: 'Kodshasan';
                        border-width: 0px;
                        background-color: green;
                        font-size: 20px;
                        padding: 10px;
                        margin-left: 0%;
                        transition: all 500ms;
                        &:hover{
                            background-color: rgb(3, 98, 3);
    
                        }
    
                        }
                    }
                }
            }
        }
    }
    
}